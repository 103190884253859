// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout } from '@/layouts'
import storage from 'store'
import { bxAnaalyse } from '@/core/icons'

// const res = ["foundation",]
// let redirect = ""
// const PermissionArr = [
//   { key: "foundation", path: "/knowledge_tree/foundation_tree" },
//   { key: "business", path: "/knowledge_tree/service_tree-form" },
//   { key: "association", path: "/knowledge_tree/correlation_of_knowledge_points" },
//   { key: "questionBank", path: "/questionbank" },
//   { key: "dimension", path: "/title_annotation" },
//   { key: "request", path: "/title_request" },
//   { key: "teachingView", path: "/teaching_view" },
//   { key: "teachingEnter", path: "/teaching_assistant/teaching_enter" },
//   { key: "teachingInspect", path: "/teaching_assistant/catalog_Check" },
//   { key: "titleSlicing", path: "/teaching_assistant/title_slicing" },
//   { key: "answerSlicing", path: "/teaching_assistant/answer_slicing" },
//   { key: "titileAssociation", path: "/teaching_assistant/answer_slicing" },
//   { key: "accountManage", path: "/account_permission/account_manage" },
//   { key: "roleManage", path: "/account_permission/role_manage" },
// ]
// if (storage.get("permissionList")) {
//   const permissionList = storage.get("permissionList")
//   for (let item of PermissionArr) {
//     if (permissionList.includes(item.key)) {
//       redirect = item.path
//       break
//     }
//   }
// } else {
//   redirect = "/knowledge_tree/foundation_tree"
// }

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/knowledge_tree/foundation_tree',
    children: [
      {
        path: '/knowledge_tree',
        redirect: '/knowledge_tree/foundation_tree',
        component: RouteView,
        // meta: { title: '知识树', icon: 'icon-a-fenleizhishishu'},
        meta: { title: '知识树', permission: ['knowledge'], icon: 'knowledge' },
        children: [
          {
            path: '/knowledge_tree/foundation_tree',
            name: 'foundation_tree',
            component: () => import('@/views/knowledge_tree/foundation_tree/foundation_tree'),
            meta: { title: '基础树', permission: ['foundation'], keepAlive: true }
          },
          {
            path: '/knowledge_tree/service_tree-form',
            name: 'service_tree',
            component: () => import('@/views/knowledge_tree/service_tree/service_tree'),
            meta: { title: '业务树', permission: ['business'], keepAlive: true }
          },
          {
            path: '/knowledge_tree/correlation_of_knowledge_points',
            name: 'correlation_of_knowledge_points',
            component: () => import('@/views/knowledge_tree/correlation_of_knowledge_points/correlation_of_knowledge_points'),
            meta: { title: '知识点关联', permission: ['association'], keepAlive: true }
          }
        ]
      },
      {
        path: '/questionbank',
        name: '/questionbank',
        meta: { title: '题库管理', permission: ['questionBank'], icon: 'questionbank' },
        component: () => import('@/views/questionbank/questionbank')
      },
      {
        path: '/title_annotation',
        name: '/title_annotation',
        meta: { title: '题目标注', permission: ['dimension'], icon: 'annotation' },
        component: () => import('@/views/title_annotation/annotation')
      },
      {
        path: '/title_request',
        name: '/title_request',
        meta: { title: '学科网题目请求', permission: ['request'], icon: 'request' },
        component: () => import('@/views/title_request/titleRequest')
      },
      {
        path: '/teaching_view',
        name: '/teaching_view',
        meta: { title: '教辅查看', permission: ['teachingView'], icon: 'teaching_view' },
        component: () => import('@/views/teaching_view/teaching_view')
      },
      {
        path: '/others_teaching_manage',
        name: '/others_teaching_manage',
        meta: { title: '第三方教辅录入管理', permission: ['othersTeachingManage'], icon: 'teaching_enter' },
        component: () => import('@/views/others_teaching_manage/others-teaching-manage')
      },
      {
        path: '/teaching_assistant',
        redirect: '/teaching_assistant/teaching_enter',
        component: RouteView,
        meta: { title: '教辅录入管理', permission: ['teachingManage'], icon: 'teaching_enter' },
        children: [
          {
            path: '/teaching_assistant/teaching_enter',
            name: 'teaching_enter',
            component: () => import('@/views/teaching_assistant/teaching_enter/teaching_enter'),
            meta: { title: '教辅录入', permission: ['teachingEnter'], keepAlive: true }
          },
          {
            path: '/teaching_assistant/catalog_Check',
            name: 'catalog_Check',
            component: () => import('@/views/teaching_assistant/catalog_Check/catalog_Check'),
            meta: { title: '目录检查', permission: ['teachingInspect'], keepAlive: true }
          },
          {
            path: '/teaching_assistant/title_slicing',
            name: 'title_slicing',
            component: () => import('@/views/teaching_assistant/title_slicing/title_slicing'),
            meta: { title: '题目切割', permission: ['titleSlicing'], keepAlive: true }
          },
          {
            path: '/teaching_assistant/answer_slicing',
            name: 'answer_slicing',
            component: () => import('@/views/teaching_assistant/answer_slicing/answer_slicing'),
            meta: { title: '答案切割', permission: ['answerSlicing'], keepAlive: true }
          },
          {
            path: '/teaching_assistant/titile_association',
            name: 'titile_association',
            component: () => import('@/views/teaching_assistant/titile_association/titile_association'),
            meta: { title: '关联识别', permission: ['titileAssociation'], keepAlive: true }
          },
        ]
      },
      {
        path: '/topic_structuring',
        name: '/topic_structuring',
        meta: { title: '题目结构化', permission: ['topicStructuring'], icon: 'topic_structuring' },
        component: () => import('@/views/topic_structuring/topicStructuring')
      },
      {
        path: '/account_permission',
        redirect: '/account_permission/account_manage',
        component: RouteView,
        meta: { title: '账号与权限', permission: ['accountUser'], icon: 'account_user' },
        children: [
          {
            path: '/account_permission/account_manage',
            name: 'account_manage',
            component: () => import('@/views/account_permission/account_manage/account_manage'),
            meta: { title: '账号管理', permission: ['accountManage'], keepAlive: true }
          },
          {
            path: '/account_permission/role_manage',
            name: 'role_manage',
            component: () => import('@/views/account_permission/role_manage/role_manage'),
            meta: { title: '角色权限管理', permission: ['roleManage'], keepAlive: true }
          },
        ]
      },
      {
        path: '/correction_and_testing',
        redirect: '/correction_and_testing/question_correction',
        component: RouteView,
        meta: { 
          title: '批改质检',
          permission: ['correctionAndTesting'],
          icon: 'question_correction',
          redDotKey: 'correction_and_quality_red_dot', //菜单红点key
          controlRedDotType:'children', //控制红点的类型,children表示子菜单中有一个有红点就显示,key表示由redDotArray控制,self表示由自身控制,none表示不控制
        },
        children: [
          {
            path: '/correction_and_testing/question_correction',
            name: '/question_correction',
            meta: { 
              title: '题目批改', 
              permission: ['questionCorrection'], 
              icon: '',
              redDotKey:"exam_correct_red_dot",
              controlRedDotType:'key', //控制红点的类型,children表示子菜单中有一个有红点就显示,key表示由redDotArray控制,self表示由自身控制,none表示不控制
              redDotArray:["high_school_red_dot",'middle_school_red_dot','primary_school_red_dot'], //当子菜单中的redDotKey包含在showSelfRedDotArray时,父菜单显示红点
            },
            component: () => import('@/views/question_correction/question-correction'),
          },
          {
            path: '/correction_and_testing/question_quality_testing',
            name: '/question_quality_testing',
            meta: { 
              title: '题目质检',
              permission: ['questionQualityTesting'],
              icon: '',
              redDotKey:"exam_quality_dot",
              controlRedDotType:'key', //控制红点的类型,children表示子菜单中有一个有红点就显示,key表示由redDotArray控制,self表示由自身控制,none表示不控制
              redDotArray:["high_school_red_dot",'middle_school_red_dot','primary_school_red_dot'], //当子菜单中的redDotKey包含在showSelfRedDotArray时,父菜单显示红点 
            },
            component: () => import('@/views/question_quality_testing/question-quality-testing'),
          },
          {
            path: '/correction_and_testing/question_correction_report_error',
            name: '/question_correction_report_error',
            meta: { 
              title: '批改报错', 
              permission: ['questionCorrectionReportError'], 
              icon: '',
              redDotKey:"error_queue_red_dot",
              controlRedDotType:'key', //控制红点的类型,children表示子菜单中有一个有红点就显示,key表示由redDotArray控制,self表示由自身控制,none表示不控制
              redDotArray:["high_school_red_dot",'middle_school_red_dot','primary_school_red_dot'], //当子菜单中的redDotKey包含在showSelfRedDotArray时,父菜单显示红点 
            },
            component: () => import('@/views/question_correction_report_error/question-correction-report-error'),
          },
        ]
      },
      {
        path: '/data_report',
        redirect: '/question_correction_statistics',
        component: RouteView,
        meta: { 
          title: '数据报表', 
          permission: ['dataReport'],
          icon: 'question_correction',
          redDotKey: 'data_report_red_dot',//父菜单红点key
          controlRedDotType:'none', //控制红点的类型,children表示子菜单中有一个有红点就显示,key表示由redDotArray控制,self表示由自身控制,none表示不控制
        },
        children: [
          {
            path: '/question_correction_statistics',
            name: '/question_correction_statistics',
            meta: { title: '批改统计', permission: ['questionCorrectionStatistics'],},
            component: () => import('@/views/question_correction_statistics/question-correction-statistics'),
          },
          {
            path: '/question_quality_statistics',
            name: '/question_quality_statistics',
            meta: { title: '质检统计', permission: ['questionQualityStatistics'], },
            component: () => import('@/views/question_quality_statistics/question-quality-statistics'),
          },
          {
            path: '/topic_data_statistics',
            name: '/topic_data_statistics',
            meta: { title: '结构化标注统计', permission: ['topicDataStatistics'], },
            component: () => import('@/views/topic_data_statistics/topic-data-statistics'),
          },
          {
            path: '/question_storage_statistics',
            name: '/question_storage_statistics',
            meta: { title: '题目入库统计', permission: ['questionStorageStatistics'], },
            component: () => import('@/views/question_storage_statistics/question-storage-statistics'),
          },
          // {
          //   path: '/test_permission',
          //   name: '/test_permission',
          //   meta: { title: '只是为了测试权限', permission: ['testPermission'], },
          //   component: () => import('@/views/test_permission/index.vue'),
          // },
          {
            path: '/my_correction_error',
            name: '/my_correction_error',
            meta: { title: '我的批错', permission: ['myCorrectionError'], icon: 'question_correction' },
            component: () => import('@/views/my_correction_error/my-correction-error'),
          },
          {
            path: '/history_correction_error',
            name: '/history_correction_error',
            meta: { title: '全部批错', permission: ['historyCorrectionError'] },
            component: () => import('@/views/history_correction_error/index.vue'),
          },
          {
            path: '/cheat_warning',
            name: '/cheat_warning',
            meta: { 
              title: '作弊预警',
              permission: ['cheatWarning'],
              redDotKey: 'cheat_warning_red_dot',
              controlRedDotType:'self', //控制红点的类型,children表示子菜单中有一个有红点就显示,key表示由redDotArray控制,self表示由自身控制,none表示不控制
            },
            component: () => import('@/views/cheat_warning/index.vue'),
          },
        ]
      },
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
