<template>
  <a-config-provider :locale="locale">
    <div id="app" style="min-width: 1400px;">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'

export default {
  data () {
    return {
    }
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  },
  methods:{
    /*
    * 监听storage事件,当用户登录时,刷新多开的页面
    */ 
    handlerStorage(event){
      console.log(event)
      if(this.$route.path === "/user/login"){
        return;
      }
      if (event.key === 'user_name' && event.newValue !== '') {
        location.reload();
      }
    },
  },
  mounted(){
    window.addEventListener('storage', this.handlerStorage);
  },
  beforeDestroy(){
    window.removeEventListener('storage',this.handlerStorage)
  },
}
</script>
<style>
/* @import './assets/icon/iconfont.css'; */
</style>
