import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import { login, getInfo, loginBy, loginPhone, configRole } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'

storage.addPlugin(expirePlugin)
const user = {
	state: {
		token: '',
		name: '',
		welcome: '',
		avatar: '',
		roles: [],
		info: {}
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_NAME: (state, { name, welcome }) => {
			state.name = name
			state.welcome = welcome
		},
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		SET_INFO: (state, info) => {
			state.info = info
		}
	},

	actions: {
		// 登录
		Login({ commit }, userInfo) {
			return new Promise((resolve, reject) => {
				loginBy(userInfo).then(response => {
					if (response.data.login_status === 2) {
						const result = response.data
						storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
						commit('SET_TOKEN', result.token)
						resolve(result)
					} else {
						reject(response.data.login_status)
					}
				}).catch(error => {
					reject(error)
				})
				// const result = {
				// 	'id': 1,
				// 	'name': '1234567',
				// 	'username': 'admin',
				// 	'password': '',
				// 	'avatar': 'https://gw.alipayobjects.com/zos/rmsportal/jZUIxmJycoymBprLOUbT.png',
				// 	'status': 1,
				// 	'telephone': '',
				// 	'lastLoginIp': '27.154.74.117',
				// 	'lastLoginTime': 1534837621348,
				// 	'creatorId': 'admin',
				// 	'createTime': 1497160610259,
				// 	'deleted': 0,
				// 	'roleId': 'admin',
				// 	'lang': 'zh-CN',
				// 	'token': '4291d7da9005377ec9aec4a71ea837f'
				// }
				// storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
				// commit('SET_TOKEN', result.token)
				// resolve()
			})
		},

		// 手机验证码登录
		LoginPhone({ commit }, userInfo) {
			return new Promise((resolve, reject) => {
				loginPhone(userInfo).then(response => {
					if (response.data.verfiy_code_vaild_status === 0) {
						const result = response.data
						storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
						commit('SET_TOKEN', result.token)
						resolve(result)
					} else if(response.data.verfiy_code_vaild_status === 2){
						const result = response.data
						storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
						commit('SET_TOKEN', result.token)
						reject(response.data.verfiy_code_vaild_status)
					}else{
						reject(response.data.verfiy_code_vaild_status)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 获取用户信息
		GetInfo({ commit }) {
			return new Promise((resolve, reject) => {
				//请求后端获取用户信息 /api/user/info
				const user_name = storage.get("user_name")
				console.log(8877,user_name);
				configRole({ user_name: user_name }).then(response => {
					const result = {
						roleId: 'admin',
						role: {
							permissionList: [],
							permissions: []
						}
					}
					response.data.auth_list.forEach(item => {
						result.role.permissionList.push(item.key)
						result.role.permissions.push({
							roleId: 'admin',
							permissionId: item.key,
							permissionName: item.title,
							actionEntitySet: []
						})
					});
					const roleManage = [
					  ["foundation","business","association"],
					  ["teachingEnter","teachingInspect","titleSlicing","answerSlicing","titileAssociation"],
					  ["accountManage","roleManage"],
					  ["questionCorrection", "questionQualityTesting", "questionCorrectionReportError"],
						["questionCorrectionStatistics", "questionQualityStatistics", "topicDataStatistics","questionStorageStatistics","myCorrectionError",'historyCorrectionError','cheatWarning']
					]
					const roleList = [
						{key:"knowledge",value:"知识树"},
						{key:"teachingManage",value:"教辅录入管理"},
						{key:"accountUser",value:"账号与权限"},
						{key:"correctionAndTesting",value:"批改质检"},
						{key:"dataReport",value:"数据报表"},
						{key:"dimension",value:"题目标注"},
						{key:"topicStructuring",value:"题目结构化"},
					  ]
					roleManage.forEach((item,index)=>{
						item.forEach(data=>{
							index === 0 && result.role.permissionList.includes(data)?result.role.permissionList.push('knowledge'):""
							index === 1 && result.role.permissionList.includes(data)?result.role.permissionList.push('teachingManage'):""
							index === 2 && result.role.permissionList.includes(data)?result.role.permissionList.push('accountUser'):""
							index === 3 && result.role.permissionList.includes(data)?result.role.permissionList.push('correctionAndTesting'):""
							index === 4 && result.role.permissionList.includes(data)?result.role.permissionList.push('dataReport'):""
						})
					})
					result.role.permissionList = [...new Set(result.role.permissionList)]
					roleList.forEach((item)=>{
						let obj = result.role.permissionList.find(child=>child.includes(item.key))
						obj?result.role.permissions.push({
							roleId: 'admin',
							permissionId: item.key,
							permissionName: item.value,
							actionEntitySet: []
						}):""
					})
					// 上面的知识树/教辅录入管理/账号与权限是2层,题目标注/结构化是3层
					// 如果树的二/三级没有选满,后端不会返回一级的key,所以需要遍历判断是否存在二/三级的key,如果有二级的key需要把一级的key加上,否则页面刷新就无法回到本来的一级页面
					const tabList = [
						["dimension/toBeMarked","dimension/waitting2Reviewed","dimension/UnableLabel","dimension/back"],
						["topicStructuring/waitting2Proof","topicStructuring/waitting2Reviewed"]
					]
					tabList.forEach((item,index)=>{
						item.forEach(data=>{
							index === 0 && result.role.permissionList.find(children=>children.includes(data))?result.role.permissionList.push('dimension'):""
							index === 1 && result.role.permissionList.find(children=>children.includes(data))?result.role.permissionList.push('topicStructuring'):""
						})
					})
					console.log(result.role.permissions,"result.role.permissions");
					storage.set("permissionList",result.role.permissionList)
					if (result.role && result.role.permissions.length > 0) {
						const role = { ...result.role }
						role.permissions = result.role.permissions.map(permission => {
							const per = {
								...permission,
								actionList: (permission.actionEntitySet || {}).map(item => item.action)
							}
							return per
						})
						role.permissionList = role.permissions.map(permission => { return permission.permissionId })
						// 覆盖响应体的 role, 供下游使用
						result.role = role

						commit('SET_ROLES', role)
						commit('SET_INFO', result)
						commit('SET_NAME', { name: result.name, welcome: welcome() })
						commit('SET_AVATAR', result.avatar)
						// 下游
						console.log(89777, result);
						resolve(result)
					} else {
						reject(new Error('getInfo: roles must be a non-null array !'))
					}
				}).catch(error => {
					reject(error)
				})
				// const roleObj = {
				//   id: 'admin',
				//   name: '管理员',
				//   describe: '拥有所有权限',
				//   status: 1,
				//   creatorId: 'system',
				//   createTime: 1497160610259,
				//   deleted: 0,
				//   permissions: [
				//     {
				//       roleId: 'admin',
				//       permissionId: 'dashboard',
				//       permissionName: '仪表盘',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'query',
				//           describe: '查询',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'delete',
				//           describe: '删除',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     },
				//     {
				//       roleId: 'admin',
				//       permissionId: 'exception',
				//       permissionName: '异常页面权限',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'query',
				//           describe: '查询',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'delete',
				//           describe: '删除',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     },
				//     {
				//       roleId: 'admin',
				//       permissionId: 'result',
				//       permissionName: '结果权限',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'query',
				//           describe: '查询',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'delete',
				//           describe: '删除',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     },
				//     {
				//       roleId: 'admin',
				//       permissionId: 'profile',
				//       permissionName: '详细页权限',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'query',
				//           describe: '查询',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'delete',
				//           describe: '删除',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     },
				//     {
				//       roleId: 'admin',
				//       permissionId: 'table',
				//       permissionName: '表格权限',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"import","defaultCheck":false,"describe":"导入"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'import',
				//           describe: '导入',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     },
				//     {
				//       roleId: 'admin',
				//       permissionId: 'form',
				//       permissionName: '表单权限',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'query',
				//           describe: '查询',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'delete',
				//           describe: '删除',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     },
				//     {
				//       roleId: 'admin',
				//       permissionId: 'order',
				//       permissionName: '订单管理',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'query',
				//           describe: '查询',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'delete',
				//           describe: '删除',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     },
				//     {
				//       roleId: 'admin',
				//       permissionId: 'permission',
				//       permissionName: '权限管理',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'delete',
				//           describe: '删除',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     },
				//     {
				//       roleId: 'admin',
				//       permissionId: 'role',
				//       permissionName: '角色管理',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'delete',
				//           describe: '删除',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     },
				//     {
				//       roleId: 'admin',
				//       permissionId: 'table',
				//       permissionName: '桌子管理',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'query',
				//           describe: '查询',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'delete',
				//           describe: '删除',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     },
				//     {
				//       roleId: 'admin',
				//       permissionId: 'user',
				//       permissionName: '用户管理',
				//       actions:
				//         '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"import","defaultCheck":false,"describe":"导入"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"},{"action":"export","defaultCheck":false,"describe":"导出"}]',
				//       actionEntitySet: [
				//         {
				//           action: 'add',
				//           describe: '新增',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'import',
				//           describe: '导入',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'get',
				//           describe: '详情',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'update',
				//           describe: '修改',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'delete',
				//           describe: '删除',
				//           defaultCheck: false
				//         },
				//         {
				//           action: 'export',
				//           describe: '导出',
				//           defaultCheck: false
				//         }
				//       ],
				//       actionList: null,
				//       dataAccess: null
				//     }
				//   ]
				// }
				// roleObj.permissions.push({
				//   roleId: 'admin',
				//   permissionId: 'support',
				//   permissionName: '超级模块',
				//   actions:
				//     '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"import","defaultCheck":false,"describe":"导入"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"},{"action":"export","defaultCheck":false,"describe":"导出"}]',
				//   actionEntitySet: [
				//     {
				//       action: 'add',
				//       describe: '新增',
				//       defaultCheck: false
				//     },
				//     {
				//       action: 'import',
				//       describe: '导入',
				//       defaultCheck: false
				//     },
				//     {
				//       action: 'get',
				//       describe: '详情',
				//       defaultCheck: false
				//     },
				//     {
				//       action: 'update',
				//       describe: '修改',
				//       defaultCheck: false
				//     },
				//     {
				//       action: 'delete',
				//       describe: '删除',
				//       defaultCheck: false
				//     },
				//     {
				//       action: 'export',
				//       describe: '导出',
				//       defaultCheck: false
				//     }
				//   ],
				//   actionList: null,
				//   dataAccess: null
				// })
				// const result = {
				// 	id: '4291d7da9005377ec9aec4a71ea837f',
				// 	name: '天野远子',
				// 	username: 'admin',
				// 	password: '',
				// 	avatar: '/avatar2.jpg',
				// 	status: 1,
				// 	telephone: '',
				// 	lastLoginIp: '27.154.74.117',
				// 	lastLoginTime: 1534837621348,
				// 	creatorId: 'admin',
				// 	createTime: 1497160610259,
				// 	merchantCode: 'TLif2btpzg079h15bk',
				// 	deleted: 0,
				// 	roleId: 'admin',
				// 	role: roleObj
				// }
				// if (result.role && result.role.permissions.length > 0) {
				//   const role = { ...result.role }
				//   role.permissions = result.role.permissions.map(permission => {
				//     const per = {
				//       ...permission,
				//       actionList: (permission.actionEntitySet || {}).map(item => item.action)
				//      }
				//     return per
				//   })
				//   role.permissionList = role.permissions.map(permission => { return permission.permissionId })
				//   // 覆盖响应体的 role, 供下游使用
				//   result.role = role

				//   commit('SET_ROLES', role)
				//   commit('SET_INFO', result)
				//   commit('SET_NAME', { name: result.name, welcome: welcome() })
				//   commit('SET_AVATAR', result.avatar)
				//   // 下游
				//   resolve(result)
				// } else {
				//   reject(new Error('getInfo: roles must be a non-null array !'))
				// }
			})
		},

		// 登出
		Logout({ commit, state }) {
			return new Promise((resolve) => {
				// logout(state.token).then(() => {
				//   commit('SET_TOKEN', '')
				//   commit('SET_ROLES', [])
				//   storage.remove(ACCESS_TOKEN)
				//   resolve()
				// }).catch((err) => {
				//   console.log('logout fail:', err)
				//   // resolve()
				// }).finally(() => {
				// })
				commit('SET_TOKEN', '')
				commit('SET_ROLES', [])
				storage.remove(ACCESS_TOKEN)
				resolve()
			})
		}

	}
}

export default user
