<template>
  <pro-layout
    :menus="menus"
    :collapsed="collapsed"
    :mediaQuery="query"
    :isMobile="isMobile"
    :handleMediaQuery="handleMediaQuery"
    :handleCollapse="handleCollapse"
    :i18nRender="i18nRender"
    :siderWidth="239"
    v-bind="settings"
  >
    <template v-slot:menuRender>
      <a-menu
        :open-keys.sync="openKeys"
        :selectedKeys="selectedKeys"
        mode="inline"
      >
        <template v-for="item in handlerMenus">
          <a-sub-menu
            :key="item.path"
            v-if="item.children && item.children.length>0"
          >
            <span slot="title"><i
                :class="`anticon-${ item?.meta?.icon }`"
                class="anticon"
              ></i><span :class="{'has-point':item.hasPoint}">{{ item?.meta?.title }}</span></span>
            <a-menu-item
              v-for="child in item.children"
              :key="child.path"
            >
              <a :href="`#${child.path}`"><span :class="{'has-point':child.hasPoint}">{{ child?.meta?.title }}</span></a></a-menu-item>
          </a-sub-menu>
          <a-menu-item
            v-else
            :key="item.path"
          >
            <a :href="`#${ item.path }`"><i
                :class="`anticon-${ item?.meta?.icon }`"
                class="anticon"
              ></i><span>{{ item?.meta?.title }}</span></a>
          </a-menu-item>
        </template>
      </a-menu>
    </template>
    <!-- Ads begin
      广告代码 真实项目中请移除
      production remove this Ads
    -->
    <!-- <ads v-if="isProPreviewSite && !collapsed"/> -->
    <!-- Ads end -->

    <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
    <template v-slot:menuHeaderRender>
      <div style="text-align: center; font-weight: bold; font-size: 30px;">
        <!-- <img src="@/assets/logo.svg" /> -->
        <div style="margin: 0; border-bottom: 2px solid #F5F5F5; height: 106px; font-size: 30px; color: #15C5CE !important;;">{{ title }}</div>
      </div>
    </template>
    <!-- 1.0.0+ 版本 pro-layout 提供 API,
          增加 Header 左侧内容区自定义
    -->
    <template v-slot:headerContentRender>
      <div>
        <!-- <a-tooltip title="刷新页面">
          <a-icon type="reload" style="font-size: 18px;cursor: pointer;" @click="() => { $message.info('只是一个DEMO') }" />
        </a-tooltip> -->
        <h2 style="font-size: 20px; font-weight: 500; color: #252929;font-family: PingFang SC-Medium, PingFang SC;">知识管理系统</h2>
      </div>
    </template>

    <!--    <setting-drawer v-if="isDev" :settings="settings" @change="handleSettingChange">
      <div style="margin: 12px 0;">
        This is SettingDrawer custom footer content.
      </div>
    </setting-drawer> -->
    <template v-slot:rightContentRender>
      <right-content :top-menu="settings.layout === 'topmenu'" />
    </template>

    <!-- custom footer / 自定义Footer -->
    <!--    <template v-slot:footerRender>
      <global-footer />
    </template> -->
    <router-view />
  </pro-layout>
</template>

<script>
import lodash from "lodash";
import { showRedDot } from "@/api/permission";
import storage from "store";
import { SettingDrawer, updateTheme } from "@ant-design-vue/pro-layout";
import { i18nRender } from "@/locales";
import { mapState } from "vuex";
import {
  CONTENT_WIDTH_TYPE,
  SIDEBAR_TYPE,
  TOGGLE_MOBILE_TYPE,
} from "@/store/mutation-types";

import defaultSettings from "@/config/defaultSettings";
import RightContent from "@/components/GlobalHeader/RightContent";
import GlobalFooter from "@/components/GlobalFooter";
import Ads from "@/components/Other/CarbonAds";
import subscriber from "@/js/subscriber.js";

export default {
  name: "BasicLayout",
  components: {
    SettingDrawer,
    RightContent,
    GlobalFooter,
    Ads,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      // preview.pro.antdv.com only use.
      isProPreviewSite:
        process.env.VUE_APP_PREVIEW === "true" &&
        process.env.NODE_ENV !== "development",
      // end
      isDev:
        process.env.NODE_ENV === "development" ||
        process.env.VUE_APP_PREVIEW === "true",
      // base
      menus: [],
      // 侧栏收起状态
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth:
          defaultSettings.layout === "sidemenu"
            ? CONTENT_WIDTH_TYPE.Fluid
            : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: true,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false,
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false,
      openKeys: [],
      handlerMenus: [], // 处理后的菜单
      selectedKeys: [], // 选中的key
      redDotData: {
        correction_and_quality_red_dot: {
          exam_correct_red_dot: {},
          exam_quality_dot: {},
          error_queue_red_dot: {},
        },
        data_report_red_dot: {},
      }, // 红点数据
      pageGetRedDotPathArray: ["/correction_and_testing/question_correction", "/correction_and_testing/question_quality_testing","/correction_and_testing/question_correction_report_error","/cheat_warning"], // 已经在页面获取红点或者通知侧边栏获取红点的页面path
    };
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
    }),
    // 有红点redDotKey的一级菜单
    redDotMenuList() {
      return this.handlerMenus.reduce((prev, item) => {
        if (item.meta?.redDotKey) {
          prev.push(item);
        }
        return prev;
      }, []);
    },
  },
  watch: {
    "$route.path": {
      handler(val) {
        // 有需要判断红点的菜单而且不在pageGetRedDotPathArray,拉取接口进行匹配
        if (this.redDotMenuList && !this.pageGetRedDotPathArray.includes(val)) {
          console.log("拉取接口");
          this.getRedDot();
        }
        // 根据path判断openkey
        this.selectedKeys = [val];
        let key = this.getParentKey(val);
        this.openKeys = [key];
      },
      deep: true,
    },
  },
  created() {
    const routes = this.mainMenu.find((item) => item.path === "/");
    this.menus = (routes && routes.children) || [];
    this.menus.forEach((item, index) => {
      if (item?.meta?.hideInMenu) {
        this.menus.splice(index, 1);
      }
    });
    this.handlerMenus = lodash.cloneDeep(this.menus);
    console.log(this.handlerMenus, "this.handlerMenus");
    this.selectedKeys = [this.$route.path];
    let key = this.getParentKey(this.$route.path);
    this.openKeys = [key];
    // 如果有批改这个菜单才处理红点
    if (this.redDotMenuList) {
      this.getRedDot();
    }
    // 处理侧栏收起状态
    window.addEventListener("resize", this.handleResize);
    this.$watch("collapsed", () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed);
    });
    this.$watch("isMobile", () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile);
    });
  },
  mounted() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Edge") > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed;
        setTimeout(() => {
          this.collapsed = !this.collapsed;
        }, 16);
      });
    }
    // this.$bus.$on("changePhaseTab", this.changePhaseTab);
    // this.$bus.$on("getRedDot", this.getRedDot);
    subscriber.on("changePhaseTab", this.changePhaseTab);
    subscriber.on("getRedDot", this.getRedDot);
  },
  beforeDestroy() {
    console.log("被销毁");
    // this.$bus.$off();
    subscriber.off();
  },
  methods: {
    async getRedDot() {
      const user_name = storage.get("user_name");
      let params = {
        phone: user_name,
      };
      try {
        let res = await showRedDot(params);
        if (res.code == 200) {
          this.redDotData = res?.data || {
            correction_and_quality_red_dot: {
              exam_correct_red_dot: {},
              exam_quality_dot: {},
              error_queue_red_dot: {},
            },
            data_report_red_dot: {},
          };
          this.handlePoint();
        } else {
          this.redDotData = {
            correction_and_quality_red_dot: {
              exam_correct_red_dot: {},
              exam_quality_dot: {},
              error_queue_red_dot: {},
            },
            data_report_red_dot: {},
          };
          this.$message.error(`获取红点提示失败,原因是${res.message}`);
        }
      } catch (error) {
        this.redDotData = {
          correction_and_quality_red_dot: {
            exam_correct_red_dot: {},
            exam_quality_dot: {},
            error_queue_red_dot: {},
          },
          data_report_red_dot: {},
        };
        console.log(error);
        this.$message.error(`获取红点提示异常,原因是${error}`);
      }
    },
    getParentKey(val) {
      let obj = this.handlerMenus.find(
        (item) =>
          item.path === val ||
          (item.children &&
            item.children.length > 0 &&
            item.children.some((children) => children.path === val))
      );
      return obj.path;
    },
    changePhaseTab(data) {
      console.log("changePhaseTab被激发");
      console.log(data, "redData");
      this.redDotData = data;
      this.handlePoint();
    },
    // 设置红点状态
    setRedDotStatus(item, status) {
      this.$set(item, "hasPoint", status);
    },

    // 检查控制类型为key时是否有红点
    checkForKeyRedDot(controlItem, redDotObj) {
      return Object.entries(redDotObj).some(
        ([key, value]) =>
          controlItem.meta.redDotArray.includes(key) && value === true
      );
    },

    // 递归获取红点对象
    getRedDotObject(redDotData, keys) {
      if (!keys || !keys.length || !redDotData) return undefined;

      const [currentKey, ...restKeys] = keys;
      if (restKeys.length === 0) {
        return redDotData[currentKey];
      } else {
        return this.getRedDotObject(redDotData[currentKey], restKeys);
      }
    },

    // 递归处理菜单项及其子菜单，并返回是否有任意子菜单项有红点
    /**
     *
     * @param menuItem 当前菜单项
     * @param redDotPath 用以找寻红点的路径
     */
    handleMenuItem(menuItem, redDotPath = [], forceSetFalse = false) {
      // 查找当前菜单项对应的红点对象
      const redDotKey = menuItem.meta.redDotKey;
      const redDotObj = this.getRedDotObject(this.redDotData, [
        ...redDotPath,
        redDotKey,
      ]);

      // 如果没有找到对应红点对象,或者是因为其父级已经没有key,需要强制将子菜单的 hasPoint 设置为 false
      if (redDotObj === undefined || forceSetFalse) {
        // 如果有子菜单，递归处理并设置它们的 hasPoint 为 false
        if (menuItem.children && Array.isArray(menuItem.children)) {
          menuItem.children.forEach((childItem) =>
            this.handleMenuItem(childItem,[...redDotPath, redDotKey],true)
          );
        }
        this.setRedDotStatus(menuItem, false);
        return false;
      }

      let hasChildWithPoint = false; // 标记是否有子菜单有红点

      // 根据controlRedDotType设置红点
      // controlRedDotType: "self" 表示当前菜单项自己控制红点,后端返回此key,并其值为true,则显示红点
      // controlRedDotType: "key" 表示当前菜单项的红点由一些key控制,后端返回的某个key控制,后端返回此key,并其值为true,则显示红点
      // controlRedDotType: "children" 表示当前菜单项的红点由子菜单控制,只要子菜单有红点,则显示红点
      // controlRedDotType: "none" 表示当前菜单项不控制红点,但继续处理子菜单
      switch (menuItem.meta.controlRedDotType) {
        case "self":
          this.setRedDotStatus(menuItem, redDotObj === true);
          hasChildWithPoint = redDotObj === true;
          break;
        case "key":
          const hasPointForKey = this.checkForKeyRedDot(menuItem, redDotObj);
          this.setRedDotStatus(menuItem, hasPointForKey);
          hasChildWithPoint = hasPointForKey;
          break;
        case "children":
          // 处理子菜单
          if (menuItem.children && Array.isArray(menuItem.children)) {
            for (let childItem of menuItem.children) {
              const childHasPoint = this.handleMenuItem(childItem, [
                ...redDotPath,
                redDotKey,
              ]);
              if (childHasPoint) {
                hasChildWithPoint = true;
              }
            }
          }
          // 如果子菜单中有任何一个hasPoint为true,则设置父级菜单的hasPoint为true
          this.setRedDotStatus(menuItem, hasChildWithPoint);
          break;
        case "none":
          // 不改变当前菜单项的hasPoint属性,但继续处理子菜单
          if (menuItem.children && Array.isArray(menuItem.children)) {
            menuItem.children.forEach((childItem) =>
              this.handleMenuItem(childItem, [...redDotPath, redDotKey])
            );
          }
          // 返回false,因为当前菜单项的hasPoint不应被改变
          return false;
        default:
          break;
      }

      return hasChildWithPoint;
    },
    /**
     * 处理红点
     */
    handlePoint() {
      // 遍历一级菜单
      this.redDotMenuList.forEach((item) => this.handleMenuItem(item));
      console.log("redDotMenuList", this.redDotMenuList);
    },
    i18nRender,
    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 1000) {
        this.collapsed = true;
      } else {
        this.collapsed = false;
      }
    },
    handleMediaQuery(val) {
      this.query = val;
      if (this.isMobile && !val["screen-xs"]) {
        this.isMobile = false;
        return;
      }
      if (!this.isMobile && val["screen-xs"]) {
        this.isMobile = true;
        this.collapsed = false;
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid;
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse(val) {
      this.collapsed = val;
    },
    handleSettingChange({ type, value }) {
      console.log("type", type, value);
      type && (this.settings[type] = value);
      switch (type) {
        case "contentWidth":
          this.settings[type] = value;
          break;
        case "layout":
          if (value === "sidemenu") {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid;
          } else {
            this.settings.fixSiderbar = false;
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed;
          }
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ant-menu-item:hover {
  color: #545757;
}
.ant-menu-item-selected {
  color: #15c5ce !important;
}
.has-point {
  position: relative;
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    background: #15c5ce;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: 0;
  }
}
</style>
<style lang="less">
@import "./BasicLayout.less";
</style>
